import { default as React } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Button, Grid, Typography, Modal, AppBar, Toolbar, IconButton, MobileStepper } from '@material-ui/core';
import { NewDeviceContainer } from './NewDeviceContainer';
import { Close, KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { default as popupMfp } from '@/common/img/popup-mfp/index';

const styles = (theme: Theme) =>
    createStyles({
        btnPopup: {
            marginTop: '-3px',
            marginLeft: '24px',
            textTransform: 'lowercase',
        },
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '80%',
            padding: 0,
            position: 'absolute',
            top: '5%',
            left: '10%',
            width: '80%',
            outline: 'none',
        },
        header: {
            width: '100%',
            backgroundColor: '#607d8b',
        },
        heading: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        close: {
            fontSize: '28px',
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
        },
        whichStep: {
            textAlign: 'center',
            zIndex: 10,
            marginBottom: '-40px',
        },
        gridBottom: {
            flexDirection: 'column',
            width: '100%',
        },
        popupContent: {
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexFlow: 'wrap',
            height: '100%',
        },
        stepper: {
            width: '100%',
        },
        imgPopup: {
            width: '80%',
            position: 'absolute',
            margin: 'auto',
            left: '0',
            right: '0',
            height: '80%',
        },
        divContent: {
            paddingLeft: '24px',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const newDeviceContainer = NewDeviceContainer.useContainer();

    const getStepDetail = (title: string, imgSrc: string) => {
        return (
            <div className={props.classes.divContent}>
                <p>{title}</p>
                <img src={imgSrc} className={props.classes.imgPopup} alt="img" />
            </div>
        );
    };
    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return getStepDetail(locale.t(locale.keys.pageTitle.mfp.popupMfp.slide1), popupMfp.popupMfp.step1);
            case 1:
                return getStepDetail(locale.t(locale.keys.pageTitle.mfp.popupMfp.slide2), popupMfp.popupMfp.step2);
            case 2:
                return getStepDetail(locale.t(locale.keys.pageTitle.mfp.popupMfp.slide3), popupMfp.popupMfp.step3);
            default:
                return 'Unknown step';
        }
    };

    return (
        <>
            <div>
                <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={newDeviceContainer.isOpenPopup} onClose={() => newDeviceContainer.setIsOpenPopup(false)}>
                    <div className={classes.commonModal}>
                        <AppBar position="static" className={classes.header}>
                            <Toolbar variant="dense">
                                <Typography color="inherit" className={classes.heading}>
                                    {locale.t(locale.keys.pageTitle.mfp.popupMfp.popupTitle)}
                                </Typography>
                                <IconButton color="inherit" className={classes.close} onClick={() => newDeviceContainer.setIsOpenPopup(false)}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Grid container className={classes.popupContent}>
                            <Grid item>{getStepContent(newDeviceContainer.activeStep)}</Grid>
                            <Grid item className={classes.gridBottom}>
                                <p className={props.classes.whichStep}>
                                    {newDeviceContainer.activeStep + 1} / {newDeviceContainer.maxSteps}
                                </p>
                                <MobileStepper
                                    steps={newDeviceContainer.maxSteps}
                                    position="static"
                                    variant="text"
                                    className={props.classes.stepper}
                                    activeStep={newDeviceContainer.activeStep}
                                    nextButton={
                                        <Button size="small" onClick={newDeviceContainer.handleNext} disabled={newDeviceContainer.activeStep === newDeviceContainer.maxSteps - 1}>
                                            {locale.t(locale.keys.pageTitle.mfp.popupMfp.next)}
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={newDeviceContainer.handleBack} disabled={newDeviceContainer.activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            {locale.t(locale.keys.pageTitle.mfp.popupMfp.back)}
                                        </Button>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);

import { useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import * as groupModel from '@/common/api/groups';
import locale from '@/common/utils/locale';

type Group = {
    id: string;
    name: string;
    avatarUrl: string;
    memberCount: number;
};

export type GroupRow = {
    wsGroups: Group[];
    groups: Group[];
};

export type FormValidateResult = {
    groupNameHelperText: string;
};

/**
 * @description グループ作成時のフォームに使用する型定義
 */
type CreateGroupForm = {
    imageDataUri: string;
    groupName: string;
    members: string[];
};

/**
 * @description グループ更新時のフォームに使用する型定義
 */
type UpdateGroupForm = {
    id: string;
    addMembers: string[];
    avatarUrl: string; // DBに保存されている、Azure blob storageのURL
    name: string;
    removeMembers: string[];
    imageDataUri?: string; // Azure blob storageにアップロードするためのデータ
};

export type UpdateGroupFormValidateResult = {
    selectedGroupHelperText: string;
    groupNameHelperText: string;
};

/**
 *
 * @param input schame.V1WorkspaceGroupsIndexResponse
 * @returns GroupRow
 * @description
 * APIから受け取った値を表示用に整形する
 */
export const mappingRow = (input: schema.V1WorkspaceGroupsIndexResponse): GroupRow => {
    const rows: GroupRow = {
        wsGroups: [],
        groups: [],
    };
    for (const group of input.groups) {
        if (group.groupName === schema.V1ObjectsDefaultGroup.Everyone) {
            rows.wsGroups.push({
                id: group.id,
                name: group.groupName,
                avatarUrl: group.avatarUrl ? group.avatarUrl : '',
                memberCount: group.members ? group.members.length : 0,
            });
        } else {
            rows.groups.push({
                id: group.id,
                name: group.groupName,
                avatarUrl: group.avatarUrl ? group.avatarUrl : '',
                memberCount: group.members ? group.members.length : 0,
            });
        }
    }

    return rows;
};

/**
 *
 * @param formText 検索フォームのテキスト
 * @param rows 表示用のグループリスト
 * @param isIncludingWSGroup Everyoneグループを検索対象に含める場合はtrueを渡す
 * @returns GroupRow
 * @description
 * グループ名の部分一致検索を行い、表示結果を返す
 */
export const groupSearch = (formText: string, rows: GroupRow, isIncludingWSGroup: boolean): GroupRow => {
    const wsGroups = isIncludingWSGroup ? rows.wsGroups.filter((group) => group.name.includes(formText)) : rows.wsGroups;
    const groups = rows.groups.filter((group) => group.name.toLowerCase().includes(formText.toLowerCase()));
    return {
        wsGroups: wsGroups,
        groups: groups,
    };
};

/**
 * @description グループ名のソート条件を変更する
 */
export const handleChangeSortGroupByName = (sort: schema.V1ObjectsSort): schema.V1ObjectsSort => {
    if (sort === schema.V1ObjectsSort.None) {
        return schema.V1ObjectsSort.Asc;
    } else if (sort === schema.V1ObjectsSort.Asc) {
        return schema.V1ObjectsSort.Desc;
    } else {
        return schema.V1ObjectsSort.None;
    }
};

/**
 * @param groups Group[] グループリスト
 * @param sort schema.V1ObjectsSort ソート条件
 * @description ソート済みグループリスト
 */
const sortGroup = (groups: Group[], sort: schema.V1ObjectsSort): Group[] => {
    const currentGroups = [...groups];
    if (sort === schema.V1ObjectsSort.Asc) {
        return currentGroups.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort === schema.V1ObjectsSort.Desc) {
        return currentGroups.sort((a, b) => b.name.localeCompare(a.name));
    }
    return currentGroups;
};

/**
 * @param groupRow
 * @param searchText 検索フォームのテキスト
 * @param rowPerPage 表示件数
 * @param offset オフセット
 * @param sort ソート条件
 * @param shouldPaging ページングを行う場合はtrueを渡す
 * @description カテゴリ分け（WSグループとそれ以外）しない場合のグループリストを取得する
 */
export const getGroupList = (groupRow: GroupRow, searchText: string, rowPerPage: number, offset: number, sort: schema.V1ObjectsSort, shouldPaging: boolean): Group[] => {
    const groups = groupSearch(searchText, groupRow, false).groups;
    const sortedGroups = sortGroup(groups, sort);
    if (!shouldPaging) {
        return sortedGroups;
    }
    return sortedGroups.slice(offset, offset + rowPerPage);
};

/**
 * @param groupName
 * @param groups 既存グループとの重複チェックを行う場合は、グループリストを渡す
 * @param isNotEveryoneCheck Everyoneのチェックを省略する場合はtrueを渡す
 * @returns validate結果
 * @description グループ名のバリデーションを行う
 */
export const validateGroupName = (groupName: string, groups?: Group[], isNotEveryoneCheck?: boolean): string => {
    // グループ名を入力してください。
    if (groupName === '') {
        return locale.t(locale.keys.memberManagement.modal.addGroup.groupName.validate.undefinedGroupName);
    }
    // グループ名は20文字以内で入力してください。
    if (groupName.length > 20) {
        return locale.t(locale.keys.memberManagement.modal.addGroup.groupName.validate.limitGroupName);
    }
    // everyoneは予約グループのため使用できません
    if (!isNotEveryoneCheck && groupName.toLowerCase() === schema.V1ObjectsDefaultGroup.Everyone.toLowerCase()) {
        return locale.t(locale.keys.memberManagement.modal.addGroup.groupName.validate.reservedGroupName, { groupName: groupName });
    }
    // グループ名の重複チェックを行う必要がない場合はスキップ
    if (groups !== undefined) {
        // 既に存在するグループ名です。
        for (const group of groups) {
            if (group.name === groupName) {
                return locale.t(locale.keys.memberManagement.modal.addGroup.groupName.validate.duplicationGroupName);
            }
        }
    }

    //「,"|」は設定不可
    const regexSpecialCharacter = new RegExp(/^[^,"|]*$/);
    if (!regexSpecialCharacter.test(groupName)) {
        return locale.t(locale.keys.memberManagement.modal.addGroup.groupName.validate.invalidCharacter);
    }

    return '';
};

/**
 *
 * @param form APIに送信するグループ作成フォーム
 * @param rows 表示用のグループリスト(同名グループが存在するか確認するため)
 * @returns validate結果
 * @description
 * グループ作成フォームのバリデーションを行う
 */
export const validateGroup = (form: CreateGroupForm, rows: GroupRow): FormValidateResult => {
    const result: FormValidateResult = {
        groupNameHelperText: '',
    };
    result.groupNameHelperText = validateGroupName(form.groupName, rows.groups);
    return result;
};

/**
 * @params form APIに送信するグループ更新フォーム(１グループずつ配列に格納する)
 * @params rows 表示用のグループリスト(同名グループが存在するか確認するため)
 * @returns validate結果
 * @description グループ更新フォームのバリデーションを行う
 */
export const validateUpdateGroup = (form: UpdateGroupForm[], groups?: Group[]): UpdateGroupFormValidateResult => {
    const result: UpdateGroupFormValidateResult = {
        selectedGroupHelperText: '',
        groupNameHelperText: '',
    };
    // グループ名のバリデーション
    for (const group of form) {
        result.groupNameHelperText = validateGroupName(group.name, groups);
        if (result.groupNameHelperText !== '') {
            return result;
        }
    }
    // グループ選択のバリデーション
    if (form.length === 0) {
        result.selectedGroupHelperText = locale.t(locale.keys.memberManagement.modal.addGroupMember.selectGroup.validate.selectedGroup);
        return result;
    }
    return result;
};

/**
 * @param selectedState string[] 現在選択中のグループIDリスト
 * @param selectId string 選択したグループID (選択済みIDを渡すと選択解除)
 * @returns string[] 選択中のグループIDリスト
 * @description グループ一覧のチェックボックスの選択状態(ステート)を反映する。
 */
export const selectGroup = (selectedState: string[], selectId: string): string[] => {
    const index = selectedState.indexOf(selectId);
    if (index === -1) {
        selectedState.push(selectId);
    } else {
        selectedState.splice(index, 1);
    }
    return selectedState;
};

/**
 * @param memberListByGroup GroupRow[] グループリスト
 * @param selectedState string[] 現在選択中のグループIDリスト
 * @param isSelectAll boolean 全選択または全解除
 * @param searchText string 検索フォームのテキスト
 * @returns string[] 選択中のグループIDリスト
 * @description グループ一覧の全選択チェックボックスの選択状態(ステート)を反映する。
 */
export const selectGroupAll = (memberListByGroup: GroupRow, selectedState: string[], isSelectAll: boolean, searchText: string): string[] => {
    for (const group of memberListByGroup.groups) {
        if (group.name.includes(searchText)) {
            if (isSelectAll) {
                if (!selectedState.includes(group.id)) {
                    selectedState.push(group.id);
                }
            } else {
                const index = selectedState.indexOf(group.id);
                if (index !== -1) {
                    selectedState.splice(index, 1);
                }
            }
        }
    }
    return selectedState;
};

/**
 * @param groupList GroupRow[] グループに所属するメンバー一覧
 * @param selectedState string[] 現在選択中のメンバーIDリスト
 * @param searchText string 検索フォームのテキスト
 * @returns boolean 全選択チェックボックスの選択状態
 * @description グループ一覧の全選択チェックボックスの選択状態を判定する。
 */
export const isGroupSelectedAll = (groupList: GroupRow, selectedState: string[], searchText: string): boolean => {
    let isFound = false;
    for (const group of groupList.groups) {
        if (group.name.includes(searchText)) {
            isFound = true;
            if (!selectedState.includes(group.id)) {
                return false;
            }
        }
    }
    return isFound;
};

/**
 *
 * @param selectedGroupList 選択済みのグループIDリスト
 * @param groupList ワークスペースに存在するグループリスト
 * @description 選択済みのグループIDリストから、一意になるようにユーザーのIDリストを取得する
 */
export const getMemberListFromGroupList = (selectedGroupList: string[], groupList: schema.V1WorkspaceGroupsShowResponse[]): string[] => {
    const memberList: string[] = [];
    for (const group of groupList) {
        if (selectedGroupList.includes(group.id)) {
            if (!group.members) continue;
            for (const member of group.members) {
                if (!memberList.includes(member.id)) {
                    memberList.push(member.id);
                }
            }
        }
    }
    return memberList;
};

/**
 * @description
 * グループ機能のUIロジックをまとめたカスタムフック
 */
export const useGroup = () => {
    const [defaultGroupRows, setDefaultGroupRows] = useState<GroupRow>({
        wsGroups: [],
        groups: [],
    });
    const [createGroupForm, setCreateGroupForm] = useState<CreateGroupForm>({
        imageDataUri: '',
        groupName: '',
        members: [],
    });
    // 複数グループを同時更新する要件があるので配列で管理する
    const [updateGroupForm, setUpdateGroupForm] = useState<UpdateGroupForm[]>([]);
    const [searchForm, setSearchForm] = useState<string>('');
    const [selectedGroup, setSelectedGroup] = useState({
        groupName: '',
        groupId: '',
        avatarUrl: '',
    });
    const [validateResult, setValidateResult] = useState<FormValidateResult | null>(null);
    const [validateUpdateResult, setValidateUpdateResult] = useState<UpdateGroupFormValidateResult | null>(null);

    const handleCreateGroupForm = (form: CreateGroupForm) => {
        setCreateGroupForm(form);
        const result = validateGroup(form, defaultGroupRows);
        setValidateResult(result);
    };

    const handleUpdateGroupForm = (form: UpdateGroupForm[]) => {
        setUpdateGroupForm(form);
        const result = validateUpdateGroup(form, defaultGroupRows.groups);
        setValidateUpdateResult(result);
    };

    /**
     * @param req
     * @param auth
     * @returns
     */
    const indexGroupData = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceGroupsIndexResponse> => {
        const group = await groupModel.indexGroup(workspaceId, auth);
        // 初回ロード時にeveryoneを選択する
        if (selectedGroup.groupName === '' && selectedGroup.groupId === '') {
            const everyone = group.groups.find((val) => val.groupName === schema.V1ObjectsDefaultGroup.Everyone);
            if (everyone) {
                setSelectedGroup({
                    groupName: everyone.groupName,
                    groupId: everyone.id,
                    avatarUrl: everyone.avatarUrl ? everyone.avatarUrl : '',
                });
            }
        }
        return group;
    };

    /**
     * @param workspaceId ワークスペースID
     * @param auth トークン
     * @description グループ作成
     */
    const createGroupData = async (workspaceId: string, auth: string): Promise<void> => {
        await groupModel.createGroup(
            {
                avatarUrl: '', // avatarUrlはblobストレージにアップロード後に取得する。
                groupName: createGroupForm.groupName,
                members: createGroupForm.members,
            },
            workspaceId,
            createGroupForm.imageDataUri,
            auth,
        );
        // グループ作成後にフォームのリセット
        setCreateGroupForm({
            imageDataUri: '',
            groupName: '',
            members: [],
        });
        return;
    };

    /**
     * @description グループ更新
     * updateGroupFormが配列なのは、複数グループを同時更新する要件（メンバー追加、削除）があるため
     */
    const updateGroupData = async (workspaceId: string, auth: string): Promise<void> => {
        const form = updateGroupForm;
        for (let i = 0; i < form.length; i += 10) {
            const chunk = form.slice(i, i + 10);
            const requests = chunk.map((group) => {
                const req: schema.V1WorkspaceGroupsUpdateRequest = {
                    avatarUrl: group.avatarUrl,
                    groupName: group.name,
                    addMembers: group.addMembers,
                    removeMembers: group.removeMembers,
                };
                return groupModel.updateGroup(req, workspaceId, group.id, auth, group.imageDataUri);
            });
            await Promise.all(requests);
        }
        // グループ更新後にフォームのリセット
        setUpdateGroupForm([]);
        return;
    };

    /**
     * @description グループ削除
     */
    const deleteGroupData = async (groupId: string, workspaceId: string, auth: string): Promise<schema.V1WorkspaceGroupsShowResponse> => {
        const deletedGroup = await groupModel.deleteGroup(groupId, workspaceId, auth);
        return deletedGroup;
    };

    /**
     * @param all trueの場合は全てのグループを返す
     */
    const getGroupRows = (all?: boolean) => {
        if (all) {
            return defaultGroupRows;
        }
        return groupSearch(searchForm, defaultGroupRows, false);
    };

    /**
     * @description Everyoneグループの更新
     */
    const updateEveryone = async (workspaceId: string, auth: string) => {
        await groupModel.updateEveryone(workspaceId, auth);
    };

    /**
     * @description グループ作成フォームのリセット
     */
    const resetCreateForm = () => {
        setCreateGroupForm({
            imageDataUri: '',
            groupName: '',
            members: [],
        });
        setValidateResult(null); // バリデーション結果にnullを入れることで、初回表示のエラーメッセージを非表示にしながら、グループ作成できないようにするボタン制御ができる。
    };

    return {
        createGroupForm,
        handleCreateGroupForm,
        setDefaultGroupRows,
        indexGroupData,
        searchForm,
        setSearchForm,
        getGroupRows,
        selectedGroup,
        setSelectedGroup,
        validateResult,
        createGroupData,
        handleUpdateGroupForm,
        updateGroupForm,
        validateUpdateResult,
        updateGroupData,
        deleteGroupData,
        updateEveryone,
        resetCreateForm,
    };
};

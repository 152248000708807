import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        content: {
            marginLeft: '22px',
            display: 'flex',
        },
        title: {
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '64px',
            letterSpacing: 'normal',
            color: '#333333',
            textAlign: 'left',
        },
        divider: {},
        link: {
            width: '2.125rem',
            lineHeight: '64px',
            float: 'left',
            cursor: 'pointer',
            '&& svg': {
                height: '20px',
                paddingTop: '5px',
                color: '#607d8b',
            },
        },
        descGrid: {
            flexGrow: 1,
        },
        desc: {
            width: '100%',
            textAlign: 'left',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
        },
        fabGrid: {
            marginRight: 40,
            flexGrow: 0,
            textAlign: 'right',
            display: 'flex',
            height: 'auto',
        },
        marginGrid: {
            height: '100%',
        },
        fab: {
            height: 0,
        },
    });

export default styles;

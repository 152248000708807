import React, { FC } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import LoadingState from '@/common/components/ui-state/LoadingState';

const styles = (theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            backgroundColor: '#143b4e',
        },
        headingModal: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        close: {
            fontSize: '28px',
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    title: string;
    onClose: () => void;
    isLoading?: boolean;
}

const ModalHeader: FC<Props> = (props) => {
    return (
        <AppBar position="static" className={props.classes.header}>
            <Toolbar variant="dense">
                <Typography color="inherit" className={props.classes.headingModal}>
                    {props.title}
                </Typography>
                <IconButton color="inherit" className={props.classes.close} onClick={props.onClose}>
                    <Close />
                </IconButton>
            </Toolbar>
            {props.isLoading && <LoadingState />}
        </AppBar>
    );
};

export default withStyles(styles)(ModalHeader);

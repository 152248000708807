import { default as React, useEffect, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles, AppBar, Typography, IconButton, Grid, TextField, Select, MenuItem, FilledInput, FormControl, InputLabel, Fab } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import logger from '@/common/utils/logger';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as model from '@/common/api/sub-workspace/inivite/mail';
import * as workspace from '@/common/api/workspace/workspace';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';

const styles = (theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            height: '48px',
            backgroundColor: '#143b4e',
            textAlign: 'center',
        },
        heading: {
            height: '24px',
            margin: '0 auto',
            padding: '14px',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        headerTitle: {
            display: 'inline-block',
            padding: '10px',
        },
        root: {
            maxHeight: '95%',
            overflow: 'auto',
            alignItems: 'center',
            padding: theme.spacing.unit * 3,
        },
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        textMailSubject: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        textMailSubjectInput: {
            fontWeight: 'bold',
        },
        textMailBody: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        textMailBodyInput: {
            fontSize: '15px',
        },
        select: {},
        formControl: {
            maxWidth: '85%',
            minWidth: '85%',
            margin: '5px',
        },
        nextButtonDiv: {
            marginTop: '26px',
            textAlign: 'center',
        },
        button: {
            color: 'white',
            margin: theme.spacing.unit,
            minWidth: '240px',
            fontWeight: 'bold',
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
    });
interface Props extends WithStyles<typeof styles> {
    mailTo: string;
    mailCC?: string[];
    displayId: string;
    language?: string;
    onClose: () => void;
    onMailSend: (limit: number) => void;
    onMailSendError: () => void;
}
export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const [values, setValues] = useState(model.New());
    const [buttonLock, setButtonLock] = useState(false);

    useEffect(() => {
        // デフォルトメール文面の言語はワークスペース言語を使用する
        setValues({
            ...values,
            to: props.mailTo,
            cc: props.mailCC ? props.mailCC.join(',') : '',
            subject: locale.t(locale.keys.invitationNewWorkspaceMail.email.subject, undefined, !props.language ? locale.Language.English : props.language),
            body: locale.t(locale.keys.invitationNewWorkspaceMail.email.intro, undefined, !props.language ? locale.Language.English : props.language),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            to: e.target.value,
            validateInit: {
                ...values.validateInit,
                to: true,
            },
        });
    };
    const handleValidateTo = (): string => {
        if (!values.validateInit.to) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.to === null ? '' : msg!.to.toString();
        return dispMessage;
    };
    const handleChangeCc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            cc: e.target.value,
            validateInit: {
                ...values.validateInit,
                cc: true,
            },
        });
    };
    const handleValidateCc = (): string => {
        if (!values.validateInit.cc) {
            return '';
        }
        if (!values.cc) {
            return '';
        }
        // ccに複数メールアドレスが指定されている場合
        if (values.cc.includes(',')) {
            const mailList = values.cc.split(',');
            // v1.8.0ではccの上限は2つ
            if (mailList.length > 2) {
                setValues({
                    ...values,
                    cc: values.cc.slice(0, -1),
                    validateInit: {
                        ...values.validateInit,
                        cc: true,
                    },
                });
            }
            const dispMessage = mailList.map((str) => {
                const ccValues = model.New();
                ccValues.cc = str.trim();
                ccValues.validateInit.cc = true;
                const msg = validator.Validate<model.Form>(ccValues, model.ccValidation(), model.NewValidation);
                const dispMessage = msg === null ? '' : msg.cc === null ? '' : msg!.cc.toString();
                return dispMessage;
            });
            return dispMessage[dispMessage.length - 1];
        }
        // ccのアドレスが1つの場合
        const msg = validator.Validate<model.Form>(values, model.ccValidation(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.cc === null ? '' : msg!.cc.toString();
        return dispMessage;
    };
    const handleChangeSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            subject: e.target.value,
            validateInit: {
                ...values.validateInit,
                subject: true,
            },
        });
    };
    const handleValidateSubject = (): string => {
        if (!values.validateInit.subject) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.subject === null ? '' : msg!.subject.toString();
        return dispMessage;
    };
    const handleChangeBody = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            body: e.target.value,
            validateInit: {
                ...values.validateInit,
                body: true,
            },
        });
    };
    const handleValidateBody = (): string => {
        if (!values.validateInit.body) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.body === null ? '' : msg!.body.toString();
        return dispMessage;
    };
    const handlePeriod = (e: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setValues({
            ...values,
            signupLimitDays: parseInt(e.target.value, 10),
        });
    };
    const buttonDisabled = () => {
        if (buttonLock) {
            return true;
        }
        if (validator.Validate<model.Form>(values, model.validations(), model.NewValidation) !== null) {
            return true;
        }
        if (values.cc) {
            return handleValidateCc() !== '';
        }
        return false;
    };
    const handleSend = () => {
        logger.debug('handleSend');
        if (buttonLock) {
            return;
        }
        setButtonLock(true);
        (async () => {
            try {
                const result = await workspace.workspaceInviteMail(appContainer.values.authorizationCode, {
                    cc: values.cc ? values.cc.split(',') : undefined,
                    displayId: props.displayId,
                    email: values.to,
                    intro: values.body,
                    signupLimitDays: values.signupLimitDays,
                    subject: values.subject,
                });
                logger.debug('workspaceInviteMail result', result);

                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.sent),
                    variant: Variants.success,
                });
                setButtonLock(false);
                props.onMailSend(values.signupLimitDays);
                props.onClose();
            } catch (e) {
                setButtonLock(false);
                props.onMailSendError();
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    return (
        <>
            <AppBar position="static" className={classes.header}>
                <div>
                    <div className={classes.headerTitle}>
                        <span className={classes.heading}>{locale.t(locale.keys.invitationNewWorkspaceMail.index)}</span>
                    </div>
                    <IconButton color="inherit" aria-label="Close" onClick={props.onClose} style={{ position: 'absolute', right: 0 }}>
                        <CloseOutlined />
                    </IconButton>
                </div>
            </AppBar>
            <Grid container className={classes.root}>
                <Grid item sm={3} />
                <Grid container item xs={12} sm={6} spacing={8}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.text}
                            label={locale.t(locale.keys.invitationNewWorkspaceMail.to)}
                            placeholder=""
                            margin="normal"
                            value={values.to}
                            onChange={handleChangeTo}
                            error={handleValidateTo() !== ''}
                            helperText={handleValidateTo()}
                            inputProps={{
                                readOnly: true,
                                style: { height: '100%' },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.text}
                            label={locale.t(locale.keys.invitationNewWorkspaceMail.cc)}
                            placeholder=""
                            margin="normal"
                            variant="filled"
                            value={values.cc}
                            inputProps={{ style: { height: '100%' } }}
                            onChange={handleChangeCc}
                            error={handleValidateCc() !== ''}
                            helperText={handleValidateCc()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="left" color="textSecondary">
                            {locale.t(locale.keys.invitationNewWorkspaceMail.intro)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            className={classes.textMailSubject}
                            value={values.subject}
                            onChange={handleChangeSubject}
                            multiline
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            error={handleValidateSubject() !== ''}
                            helperText={handleValidateSubject()}
                            inputProps={{ className: classes.textMailSubjectInput, style: { height: '100%' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.textMailBody}
                            value={values.body}
                            onChange={handleChangeBody}
                            multiline
                            rows={7}
                            rowsMax={15}
                            variant="outlined"
                            error={handleValidateBody() !== ''}
                            helperText={handleValidateBody()}
                            inputProps={{ className: classes.textMailBodyInput, style: { height: '100%' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="filled-period" variant="filled">
                                {locale.t(locale.keys.invitationNewWorkspaceMail.invitationLimit.index)}
                            </InputLabel>
                            <Select
                                className={classes.select}
                                id="period"
                                value={values.signupLimitDays}
                                onChange={handlePeriod}
                                input={<FilledInput name="period" id="filled-period" classes={{ input: classes.selectInput }} />}
                                inputProps={{
                                    style: { height: '1.46em', lineHeight: 'normal' },
                                }}
                            >
                                <MenuItem value={7}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.day', 7, { n: 7 })}</MenuItem>
                                <MenuItem value={30}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.day', 30, { n: 30 })}</MenuItem>
                                <MenuItem value={0}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.infinity', 1, { n: 0 })}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.nextButtonDiv}>
                        <Fab variant="extended" className={classes.button} size="large" color="secondary" disabled={buttonDisabled()} onClick={handleSend}>
                            {locale.t(locale.keys.invitationNewWorkspaceMail.send)}
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item sm={3} />
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);

import { default as React, useEffect, useState } from 'react';
import { Check, Block, Edit, Search, AddCircleOutline, Delete, UnfoldMore } from '@material-ui/icons';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Checkbox, TextField, Grid, Button, Typography } from '@material-ui/core';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import locale from '@/common/utils/locale';
import CovasAvatar from '@/common/components/CovasAvatar';
import { MemberContainer } from './MemberContainer';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import { getMemberList } from '@/common/models/member/useMember';
import * as schema from '@/bundles/schema/typescript/schema';
import routes from '@/admin/constants/routes';

const styles = (theme: Theme) =>
    createStyles({
        pencil: {
            color: 'orange',
        },
        avatarRow: {
            width: '0.5%',
            padding: 0,
        },
        root: {
            width: '100%',
            overflowX: 'auto',
        },
        iconButton: {
            padding: 10,
        },
        table: {
            minWidth: 700,
            padding: 0,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        tableCell: {
            paddingInline: '4px',
            paddingTop: 0,
            paddingBottom: 0,
            height: 30,
        },
        oddRow: {
            background: '#f2f4f5',
        },
        evenRow: {
            background: 'inherit',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
        inputRoot: {
            width: '100%',
            height: 60,
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = MemberContainer.useContainer();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [memberList, setMemberList] = useState(
        getMemberList(
            container.member.memberList.rows,
            container.member.form.searchText,
            DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE,
            container.member.form.sortByName,
            container.member.form.selectedMemberIdList,
        ),
    );

    // Everyoneの場合のみメンバーではなくユーザーと呼称する
    const isWorkspaceGroup = container.group.selectedGroup.groupName === schema.V1ObjectsDefaultGroup.Everyone;

    // メンバー一覧のステート更新
    // APIからのデータフェッチ時、選択グループ変更時、検索文字列変更時、ソート順変更時、ページング処理時に実行される
    useEffect(() => {
        const refreshMember = container.member.onSelectGroup(container.group.selectedGroup.groupId);
        setMemberList(
            getMemberList(refreshMember.rows, container.member.form.searchText, rowsPerPage, page * rowsPerPage, container.member.form.sortByName, container.member.form.selectedMemberIdList),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.member.allmemberWithNotVerified, container.group.selectedGroup, container.member.form, page, rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    const getEmptyRowCount = () => {
        return rowsPerPage - Math.min(rowsPerPage, memberList.rows.length);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TextField
                                            placeholder={
                                                container.group.selectedGroup.groupName
                                                    ? isWorkspaceGroup
                                                        ? locale.t(locale.keys.memberManagement.search)
                                                        : locale.t(locale.keys.memberManagement.searchFromGroupMember)
                                                    : locale.t(locale.keys.memberManagement.search) // グループのロードができていない場合は「ユーザー」と表示
                                            }
                                            variant="outlined"
                                            margin={'dense'}
                                            fullWidth
                                            style={{ marginBlock: '4px' }}
                                            value={container.member.form.searchText}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                                container.member.setForm({ ...container.member.form, searchText: e.target.value })
                                            }
                                        />
                                    </Grid>
                                    <Grid item container direction="row" style={{ justifyContent: 'space-between' }}>
                                        <Grid item>
                                            <Button
                                                disabled={container.member.form.selectedMemberIdList.length === 0}
                                                style={{ fontSize: 14, fontWeight: 'normal' }}
                                                onClick={() =>
                                                    container.openModal({
                                                        addGroupModal: false,
                                                        addGroupMemberModal: true,
                                                        settingGroupModal: {
                                                            open: false,
                                                            groupId: '',
                                                            groupName: '',
                                                            avatarUrl: '',
                                                        },
                                                        deleteGroupModal: {
                                                            open: false,
                                                            groupId: '',
                                                            groupName: '',
                                                            memberCount: 0,
                                                        },
                                                        deleteGroupMemberModal: false,
                                                        deleteWorkspaceUserModal: false,
                                                    })
                                                }
                                            >
                                                <AddCircleOutline style={{ width: 20, height: 20, marginInlineEnd: 4 }} />
                                                {container.group.selectedGroup.groupName
                                                    ? isWorkspaceGroup
                                                        ? locale.t(locale.keys.memberManagement.addGroupMember)
                                                        : locale.t(locale.keys.memberManagement.addGroupMemberfromGroup)
                                                    : locale.t(locale.keys.memberManagement.addGroupMember) // グループのロードができていない場合は「ユーザー」と表示
                                                }
                                            </Button>
                                            {// 初回ロードが終了してグループが選択されているかつ、選択されたグループがEveryoneでない場合にグループメンバー削除ボタンを表示する
                                            container.group.selectedGroup.groupId !== '' && container.group.selectedGroup.groupName !== schema.V1ObjectsDefaultGroup.Everyone && (
                                                <Button
                                                    disabled={container.member.form.selectedMemberIdList.length === 0}
                                                    style={{ fontSize: 14, fontWeight: 'normal', textTransform: 'none' }}
                                                    onClick={() =>
                                                        container.openModal({
                                                            addGroupModal: false,
                                                            addGroupMemberModal: false,
                                                            settingGroupModal: {
                                                                open: false,
                                                                groupId: '',
                                                                groupName: '',
                                                                avatarUrl: '',
                                                            },
                                                            deleteGroupModal: {
                                                                open: false,
                                                                groupId: '',
                                                                groupName: '',
                                                                memberCount: 0,
                                                            },
                                                            deleteGroupMemberModal: true,
                                                            deleteWorkspaceUserModal: false,
                                                        })
                                                    }
                                                >
                                                    <Delete style={{ width: 20, height: 20, marginInlineEnd: 4 }} />
                                                    {locale.t(locale.keys.memberManagement.deleteGroupMember, { groupName: container.group.selectedGroup.groupName })}
                                                </Button>
                                            )}
                                        </Grid>

                                        {// 初回ロードが終了してグループが選択されているかつ、選択されたグループがEveryoneの場合にワークスペースメンバー削除ボタンを表示する
                                        container.group.selectedGroup.groupId !== '' && container.group.selectedGroup.groupName === schema.V1ObjectsDefaultGroup.Everyone && (
                                            <Button
                                                disabled={container.member.form.selectedMemberIdList.length === 0}
                                                style={{ fontSize: 14, fontWeight: 'normal', textTransform: 'none' }}
                                                onClick={() =>
                                                    container.openModal({
                                                        addGroupModal: false,
                                                        addGroupMemberModal: false,
                                                        settingGroupModal: {
                                                            open: false,
                                                            groupId: '',
                                                            groupName: '',
                                                            avatarUrl: '',
                                                        },
                                                        deleteGroupModal: {
                                                            open: false,
                                                            groupId: '',
                                                            groupName: '',
                                                            memberCount: 0,
                                                        },
                                                        deleteGroupMemberModal: false,
                                                        deleteWorkspaceUserModal: true,
                                                    })
                                                }
                                            >
                                                <Delete style={{ width: 20, height: 20, marginInlineEnd: 4 }} />
                                                {locale.t(locale.keys.memberManagement.deleteWorkspaceMember)}
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item container direction="row">
                                        <Typography>
                                            {locale.t(locale.keys.memberManagement.selectedMenerNum, {
                                                n: container.member.form.selectedMemberIdList.length === 0 ? '0' : container.member.form.selectedMemberIdList.length,
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.headerFont}>
                            <TableCell className={classes.tableCell} style={{ width: 40 }} align="center">
                                <Checkbox
                                    checked={container.member.isSelectAllMember()}
                                    onChange={() => {
                                        container.member.onSelectAllMember();
                                    }}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ width: 60 }} />
                            <TableCell className={classes.tableCell} align="left">
                                <div className={classes.gridInsideHeaderCell}>
                                    <p className={classes.gridInsideHeaderCellText}>{`${locale.t(locale.keys.common.name)}/${locale.t(locale.keys.common.email)}`}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                    <IconButton onClick={container.member.changeSortByName}>
                                        <UnfoldMore />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                {locale.t(locale.keys.memberManagement.role.index)}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                {locale.t(locale.keys.memberManagement.accountState.index)}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                {locale.t(locale.keys.memberManagement.operation)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberList.rows.map((row, index) => (
                            <TableRow key={row.id} className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                <TableCell className={classes.tableCell} style={{ width: 40 }} align="center">
                                    <Checkbox checked={container.member.form.selectedMemberIdList.includes(row.objId)} onChange={() => container.member.onSelectMember(row.objId)} />
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: 60 }} align="center">
                                    <CovasAvatar size={40} seed={row.email} src={row.avatarUrl} onClick={() => {}} color="disabled" />
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left">
                                    <Grid container direction="column">
                                        <Grid item style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {row.name}
                                        </Grid>
                                        <Grid item style={{ color: 'gray' }}>
                                            {row.email}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left">
                                    {row.role[1]}
                                </TableCell>
                                {row.active ? (
                                    <TableCell className={classes.tableCell} align="center">
                                        <Check style={{ marginBottom: -5 }} />
                                        {locale.t(locale.keys.memberManagement.active.index)}
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.tableCell} align="center">
                                        <Block style={{ marginBottom: -5 }} />
                                        {locale.t(locale.keys.memberManagement.inactive.index)}
                                    </TableCell>
                                )}
                                <TableCell className={classes.tableCell} align="center">
                                    <Link to={`${routes.user.index}/${row.objId}`}>
                                        <IconButton aria-label="Edit">
                                            <Edit className={classes.pencil} />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                        {getEmptyRowCount() > 0 && (
                            <TableRow style={{ height: 49 * getEmptyRowCount() }}>
                                <TableCell colSpan={100} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={memberList.memberTotal}
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                    onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                />
            </div>
        </Paper>
    );
};

export default withRouter(withStyles(styles)(Component));

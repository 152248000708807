import { default as React } from 'react';
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    IconButton,
    ListSubheader,
    TextField,
    Button,
    Menu,
    MenuItem,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { MemberContainer, initialModalState } from './MemberContainer';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import locale from '@/common/utils/locale';
import { CovasGroupAvatar } from '@/common/components/CovasAvatar';
import userRole from '@/common/constants/userRole';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            maxHeight: '100%',
            overflow: 'auto',
        },
        listRoot: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        listSubHeaderText: {
            fontSize: 12,
            fontWeight: 'bold',
            textAlign: 'left',
        },
    });

interface Props extends WithStyles<typeof styles> {}

type GroupMenuObject = {
    name: string;
    action: () => void;
    disabled: boolean;
};

const GroupTable: React.FC<Props> = (props) => {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();
    // Modalに渡すためのグループ情報を保存したステート
    const [anchorMenuGroup, setAnchorMenuGroup] = React.useState({
        groupId: '',
        groupName: '',
        memberCount: 0,
        avatarUrl: '',
    });

    // 各メニューのオブジェクト
    const groupMenuObjects = (groupId: string, groupName: string, memberCount: number, avatarUrl: string): GroupMenuObject[] => {
        return [
            {
                name: locale.t(locale.keys.memberManagement.groupList.groupList.menu.settings),
                action: () =>
                    memberContainer.openModal({
                        addGroupModal: false,
                        addGroupMemberModal: false,
                        settingGroupModal: {
                            open: true,
                            groupId: groupId,
                            groupName: groupName,
                            avatarUrl: avatarUrl,
                        },
                        deleteGroupModal: {
                            open: false,
                            groupId: '',
                            groupName: '',
                            memberCount: 0,
                        },
                        deleteGroupMemberModal: false,
                        deleteWorkspaceUserModal: false,
                    }),
                disabled: false,
            },
            {
                name: locale.t(locale.keys.memberManagement.groupList.groupList.menu.delete),
                action: () =>
                    memberContainer.openModal({
                        addGroupModal: false,
                        addGroupMemberModal: false,
                        settingGroupModal: {
                            open: false,
                            groupId: '',
                            groupName: '',
                            avatarUrl: '',
                        },
                        deleteGroupModal: {
                            open: true,
                            groupId: groupId,
                            groupName: groupName,
                            memberCount: memberCount,
                            avatarUrl: avatarUrl,
                        },
                        deleteGroupMemberModal: false,
                        deleteWorkspaceUserModal: false,
                    }),
                disabled: false,
            },
        ];
    };

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, groupId: string, groupName: string, memberCount: number, avatarUrl: string) => {
        //それぞれのメニューをクリックしたときに渡すグループ情報を保存
        setAnchorMenuGroup({
            groupId: groupId,
            groupName: groupName,
            memberCount: memberCount,
            avatarUrl: avatarUrl,
        });
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        event.preventDefault();
    };

    const handleCloseAndAction = (event: React.MouseEvent<HTMLButtonElement>, action: () => void) => {
        action();
        setAnchorEl(null);
        event.preventDefault();
    };

    return (
        <Paper className={classes.root}>
            <List className={classes.listRoot}>
                <ListSubheader className={classes.listSubHeaderText}>{locale.t(locale.keys.memberManagement.groupList.wsGroupList.title)}</ListSubheader>
                {memberContainer.group.getGroupRows().wsGroups.map((val) => (
                    <ListItem
                        button
                        selected={memberContainer.group.selectedGroup.groupId === val.id}
                        onClick={() => {
                            memberContainer.group.setSelectedGroup({
                                groupId: val.id,
                                groupName: val.name,
                                avatarUrl: val.avatarUrl,
                            });
                        }}
                        key={val.id}
                    >
                        <ListItemAvatar>
                            <CovasGroupAvatar size={40} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={val.name}
                            primaryTypographyProps={{ style: { fontSize: 12, fontWeight: 'bold', color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                        />
                        {appContainer.values.signinWorkspaceUserObject.role === userRole.externalAdmin && (
                            <ListItemSecondaryAction>
                                <Button
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        memberContainer.updateEveryone();
                                    }}
                                >
                                    {locale.t(locale.keys.memberManagement.groupList.wsGroupList.updateWsGroup)}
                                </Button>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))}

                <ListSubheader className={classes.listSubHeaderText}>{locale.t(locale.keys.memberManagement.groupList.groupList.title)}</ListSubheader>
                <ListItem style={{ paddingBlock: '0px', marginInline: 'auto' }}>
                    <TextField
                        placeholder={locale.t(locale.keys.memberManagement.groupList.groupList.search.placeholder)}
                        variant="outlined"
                        margin={'dense'}
                        fullWidth
                        style={{ marginBlock: '4px' }}
                        InputLabelProps={{ style: { fontSize: 12, transform: 'translate(14px, 10px) scale(1)' } }}
                        InputProps={{ style: { fontSize: 12, height: 30 } }}
                        value={memberContainer.group.searchForm}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            memberContainer.group.setSearchForm(e.target.value);
                        }}
                    />
                </ListItem>
                <ListItem style={{ paddingBlock: '0px', marginInline: 'auto' }}>
                    <Button
                        onClick={() => {
                            memberContainer.openModal({ ...initialModalState, addGroupModal: true });
                        }}
                        variant="contained"
                        style={{ fontSize: 12, height: 24, width: '100%', marginInline: 'auto' }}
                    >
                        {locale.t(locale.keys.memberManagement.groupList.groupList.create)}
                    </Button>
                </ListItem>
                {memberContainer.group.getGroupRows().groups.map((val) => (
                    <ListItem
                        button
                        selected={memberContainer.group.selectedGroup.groupId === val.id}
                        onClick={() => {
                            memberContainer.group.setSelectedGroup({
                                groupId: val.id,
                                groupName: val.name,
                                avatarUrl: val.avatarUrl,
                            });
                        }}
                        key={val.id}
                    >
                        <ListItemAvatar>
                            <CovasGroupAvatar size={40} src={val.avatarUrl} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={val.name}
                            primaryTypographyProps={{
                                style: { fontSize: 12, fontWeight: 'bold', color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' /**空白は詰めない */ },
                            }}
                            secondary={locale.t(locale.keys.memberManagement.groupList.groupList.group.count, { memberCount: val.memberCount === 0 ? '0' : val.memberCount })}
                            secondaryTypographyProps={{ style: { fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={(e) => handleClick(e, val.id, val.name, val.memberCount, val.avatarUrl)} style={{ height: 30, width: 30 }}>
                                <MoreVert />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                <Menu anchorEl={anchorEl} disableAutoFocusItem open={Boolean(anchorEl)} onClose={handleClose}>
                    {groupMenuObjects(anchorMenuGroup.groupId, anchorMenuGroup.groupName, anchorMenuGroup.memberCount, anchorMenuGroup.avatarUrl).map((groupMenuObjects, index) => {
                        return (
                            <MenuItem key={index} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCloseAndAction(event, groupMenuObjects.action)} disabled={groupMenuObjects.disabled}>
                                {groupMenuObjects.name}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </List>
        </Paper>
    );
};

export default withStyles(styles)(GroupTable);

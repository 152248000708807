import { useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import * as memberModel from '@/common/api/workspace/workspace';
import * as workspaceUserModel from '@/common/api/workspaceuser/workspaceuser';
import locale from '@/common/utils/locale';
import userRole from '@/common/constants/userRole';

export interface Row {
    id: number;
    objId: string;
    avatarUrl: string;
    name: string;
    phonetic: string;
    email: string;
    role: [number, string];
    active: boolean;
    language: schema.Language;
    contactEmail: string;
    isChecked: boolean;
}

/**
 * ワークスペースユーザー
 */
interface Workspaceuser extends schema.V1ObjectsWorkspaceuserMedium {
    active: boolean;
    analyticsReportTarget?: boolean;
    avatarUrl: string;
    contactEmail: string;
    id: string;
    invitationEmail: string;
    language: schema.Language;
    name: string;
    phoneticName: string;
    role: number;
}

/**
 * グループごとのワークスペースユーザー
 */
interface MemberListByGroup {
    avatarUrl?: string;
    groupName: string;
    id: string;
    members?: Workspaceuser[];
}

const roleToString = (role: number): string => {
    switch (role) {
        case userRole.systemAdmin:
            return locale.t(locale.keys.common.role.systemAdmin);
        case userRole.admin:
            return locale.t(locale.keys.common.role.admin);
        case userRole.externalAdmin:
            return locale.t(locale.keys.common.role.externalAdmin);
        case userRole.member:
            return locale.t(locale.keys.common.role.user);
    }
    return '';
};

/**
 * @param input schema.V1WorkspaceuserIndexResponse
 * @returns Row[]
 * @description
 */
export const mappingRow = (input: Workspaceuser[]): Row[] => {
    const rows: Row[] = [];
    for (const user of input) {
        rows.push({
            id: rows.length + 1,
            objId: user.id,
            avatarUrl: user.avatarUrl,
            name: user.name,
            phonetic: user.phoneticName,
            email: user ? user.invitationEmail : '',
            role: user ? [user.role, roleToString(user.role)] : [0, ''],
            active: user.active,
            language: user ? user.language : schema.Language.En,
            contactEmail: user ? user.contactEmail : '',
            isChecked: false,
        });
    }
    return rows;
};

/**
 * @param memberListByGroup MemberListByGroup[]
 * @param groupId グループID
 * @returns Row[]
 * @description グループIDに紐づくメンバーリストを取得する
 */
const selectMemberListByGroup = (memberListByGroup: MemberListByGroup[], groupId: string): Row[] => {
    const memberList = memberListByGroup.find((group) => group.id === groupId);
    if (memberList && memberList.members) {
        return mappingRow(memberList.members);
    }
    return [];
};

/**
 * @param rows  Row[]
 * @param searchText 検索フォームのテキスト
 * @returns Row[]
 * @description 検索フォームのテキストに一致するメンバーリストを取得する
 */
const searchMemberList = (rows: Row[], searchText: string): Row[] => {
    if (searchText === '') {
        return rows;
    }
    const searchedRows = rows.filter((row) => {
        const name = row.name.toLowerCase();
        const email = row.email.toLowerCase();
        const search = searchText.toLowerCase();
        return name.includes(search) || email.includes(search);
    });
    return searchedRows;
};

/**
 * @param rows Row[]
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset 表示開始位置(rowPerPage * page)
 * @returns Row[]
 * @description ページング処理を行う
 */
const pagingMemberList = (rows: Row[], rowPerPage: number, offset: number): Row[] => {
    const pagedRows = rows.slice(offset, offset + rowPerPage);
    return pagedRows;
};

/**
 * @param rows Row[]
 * @param sortName schema.V1ObjectsSort
 * @returns Row[]
 * @description ソート処理を行う
 */
const sortMemberList = (rows: Row[], sortName: schema.V1ObjectsSort): Row[] => {
    const currentRows = [...rows];
    const sortedRows = currentRows.sort((a, b) => {
        if (sortName === schema.V1ObjectsSort.Asc) {
            return a.name.localeCompare(b.name); // localeCompareは大文字小文字を考慮したソート
        } else if (sortName === schema.V1ObjectsSort.Desc) {
            return b.name.localeCompare(a.name);
        } else {
            return 0;
        }
    });
    return sortedRows;
};

/**
 * @param memberListByGroup Row[]
 * @param selectedMemberIdList string[] 選択されたメンバーidの配列
 * @returns Row[] テーブルに表示されるメンバーリスト
 * @description メンバー一覧のチェックボックスの選択状態を反映する。
 */
const selectedMemberList = (memberListByGroup: Row[], selectedMemberIdList: string[]): Row[] => {
    return memberListByGroup.map((member) => {
        return {
            ...member,
            isChecked: selectedMemberIdList.includes(member.objId),
        };
    });
};

/**
 * @param memberList 取得したメンバー情報
 * @param searchText 検索フォームのテキスト
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset 表示開始位置
 * @param sort schema.V1ObjectsSort
 * @param selectedMemberIdList string[] 選択されたメンバーidの配列
 * @returns テーブルに表示されるメンバーリスト
 */
export const getMemberList = (memberList: Row[], searchText: string, rowPerPage: number, offset: number, sort: schema.V1ObjectsSort, selectedMemberIdList: string[]) => {
    const searchedRows = searchMemberList(memberList, searchText);
    const sortedRows = sortMemberList(searchedRows, sort);
    const pagedRows = pagingMemberList(sortedRows, rowPerPage, offset);
    const selectedRows = selectedMemberList(pagedRows, selectedMemberIdList);
    return {
        rows: selectedRows,
        memberTotal: searchedRows.length, // 絞り込み条件を適用した後のメンバー数
    };
};

export const sortMemberListbyName = (currentSortState: schema.V1ObjectsSort): schema.V1ObjectsSort => {
    if (currentSortState === schema.V1ObjectsSort.None) {
        return schema.V1ObjectsSort.Asc;
    } else if (currentSortState === schema.V1ObjectsSort.Asc) {
        return schema.V1ObjectsSort.Desc;
    } else {
        return schema.V1ObjectsSort.None;
    }
};

/**
 * @param selectedState string[] 現在選択中のメンバーIDリスト
 * @param selectId string 選択したメンバーID (選択済みIDを渡すと選択解除)
 * @returns string[] 選択中のメンバーIDリスト
 * @description メンバー一覧のチェックボックスの選択状態(ステート)を反映する。
 */
export const selectMember = (selectedState: string[], selectId: string): string[] => {
    const index = selectedState.indexOf(selectId);
    if (index === -1) {
        selectedState.push(selectId);
    } else {
        selectedState.splice(index, 1);
    }
    return selectedState;
};

/**
 * @param rows メンバー一覧
 * @param selectedState string[] 現在選択中のメンバーIDリスト
 * @param isSelectAll boolean 全選択または全解除
 * @param searchText string 検索フォームのテキスト
 * @returns string[] 選択中のメンバーIDリスト
 * @description メンバー一覧の全選択チェックボックスの選択状態(ステート)を反映する。
 */
export const selectMemberAll = (rows: Row[], selectedState: string[], isSelectAll: boolean, searchText: string): string[] => {
    const searchedRows = searchMemberList(rows, searchText);
    if (isSelectAll) {
        searchedRows.forEach((row) => {
            if (selectedState.indexOf(row.objId) === -1) {
                selectedState.push(row.objId);
            }
        });
    } else {
        searchedRows.forEach((row) => {
            const index = selectedState.indexOf(row.objId);
            if (index !== -1) {
                selectedState.splice(index, 1);
            }
        });
    }
    return selectedState;
};

/**
 * @param rows メンバー一覧
 * @param selectedState string[] 現在選択中のメンバーIDリスト
 * @param searchText string 検索フォームのテキスト
 * @returns boolean 全選択チェックボックスの選択状態
 * @description メンバー一覧の全選択チェックボックスの選択状態を判定する。
 */
export const isMemberSelectedAll = (rows: Row[], selectedState: string[], searchText: string): boolean => {
    const searchedRows = searchMemberList(rows, searchText);
    if (searchedRows.length === 0) {
        return false;
    }
    return searchedRows.every((row) => selectedState.includes(row.objId));
};

export const useMember = () => {
    const [memberList, setMemberList] = useState<{
        rows: Row[];
        memberTotal: number;
    }>({
        rows: [],
        memberTotal: 0,
    });
    const [memberListByGroup, setMemberListByGroup] = useState<MemberListByGroup[]>([]);
    const [allmemberWithNotVerified, setAllMemberWithNotVerified] = useState<schema.V1WorkspaceuserIndexResponse>();
    type Form = {
        searchText: string;
        sortByName: schema.V1ObjectsSort;
        selectedMemberIdList: string[];
    };
    const [form, setForm] = useState<Form>({
        searchText: '',
        sortByName: schema.V1ObjectsSort.None,
        selectedMemberIdList: [] as string[],
    });

    /**
     * @description ワークスペースユーザー一覧取得
     */
    const indexWorkspaceuser = async (workspaceId: string, auth: string) => {
        const members = await memberModel.findWorkspaceUser(workspaceId, auth);
        return members;
    };

    /**
     * @description メンバー削除
     */
    const deleteWorkspaceusers = async (userIdList: string[], auth: string) => {
        const result: schema.V1WorkspaceuserDestroyResponse[] = [];
        for (let i = 0; i < userIdList.length; i += 10) {
            const chunk = userIdList.slice(i, i + 10);
            const requests = chunk.map((userId) => {
                return workspaceUserModel.removeUser(userId, auth);
            });
            const deleteUsers = await Promise.all(requests);
            result.push(...deleteUsers);
        }
        return result;
    };

    const changeSortByName = () => {
        const sort = sortMemberListbyName(form.sortByName);
        setForm({
            ...form,
            sortByName: sort,
        });
    };

    const onSelectGroup = (groupId: string) => {
        const selected = selectMemberListByGroup(memberListByGroup, groupId);
        setMemberList({
            rows: selected,
            memberTotal: selected.length,
        });
        return {
            rows: selected,
            memberTotal: selected.length,
        };
    };

    const onSelectMember = (selectId: string) => {
        const selected = selectMember(form.selectedMemberIdList, selectId);
        setForm({
            ...form,
            selectedMemberIdList: [...selected],
        });

        // 選択状態を表の表示用ステートに反映
        const updateState = memberList.rows.map((member) => {
            if (selected.includes(member.objId)) {
                return {
                    ...member,
                    isChecked: true,
                };
            } else {
                return {
                    ...member,
                    isChecked: false,
                };
            }
        });
        setMemberList({
            ...memberList,
            rows: updateState,
        });
    };

    const onSelectAllMember = () => {
        const isSelectedAll = isMemberSelectedAll(memberList.rows, form.selectedMemberIdList, form.searchText);
        const selected = selectMemberAll(memberList.rows, form.selectedMemberIdList, !isSelectedAll, form.searchText);
        setForm({
            ...form,
            selectedMemberIdList: [...selected],
        });

        // 選択状態を表の表示用ステートに反映
        const updateState = memberList.rows.map((member) => {
            if (selected.includes(member.objId)) {
                return {
                    ...member,
                    isChecked: true,
                };
            } else {
                return {
                    ...member,
                    isChecked: false,
                };
            }
        });
        setMemberList({
            ...memberList,
            rows: updateState,
        });
    };

    const isSelectAllMember = () => {
        return isMemberSelectedAll(memberList.rows, form.selectedMemberIdList, form.searchText);
    };

    const resetForm = () => {
        setForm({
            searchText: '',
            sortByName: schema.V1ObjectsSort.None,
            selectedMemberIdList: [] as string[],
        });
    };

    return {
        memberListByGroup,
        setMemberListByGroup,
        allmemberWithNotVerified,
        setAllMemberWithNotVerified,
        indexWorkspaceuser,
        form,
        setForm,
        changeSortByName,
        onSelectMember,
        onSelectAllMember,
        isSelectAllMember,
        resetForm,
        deleteWorkspaceusers,
        memberList,
        setMemberList,
        onSelectGroup,
    };
};
